@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;

[data-sparta-container] .spa-tab__list-item-dropdown {

  .spa-card__head-content,
  .spa-card__foot-content {
    display: block;
    padding: 0 $spacing-16;
    margin-top: $spacing-16;
  }

  .spa-card__head-content-wrapper {
    flex-grow: 1;
  }

  .spa-card__head-content .spa-card__title {
    margin-right: -$spacing-16;
    margin-left: -$spacing-16;
  }

  .spa-card__head {
    margin-bottom: 0;

    &-content {
      .spa-card__title {
        margin-top: 0;
      }
    }
  }
}


[data-sparta-container] .spa-tab--standard + .spa-panels__wrapper {
  .spa-tab__list-item-dropdown {
    position: relative;
  }
}


[data-sparta-container] .spa-tab__list-item-dropdown,
[data-sparta-container] .spa-tab__list-panel-item {
  .spa-tab__list-item-dropdown-spinner {
    text-align: center;

    &-icon {
      margin: 0;
    }

    &--hide {
      display: none;
    }
  }
}